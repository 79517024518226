import styled from 'styled-components'
import { AuthenticationHeader } from './Header'
import { CompanyLogo } from '../../Global/CompanyLogo'
import AuthenicationContentbg from '../../../static/images/logo/auth-bg.svg'
import { useNavigate } from 'react-router-dom'

const StyledContainer = styled.div`
  height: 100vh;
  background-image: url(${AuthenicationContentbg});
  background-size: cover;
  background-position: center;
`

const AuthMainOuter = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .overlay {
    position: absolute;
    z-index: 1;
    opacity: 0.7;
    background-color: var(--custom-secondary-bg);
    width: 100%;
    height: 100%;
    right: 0;
  }
  .BrndLogin {
    width: 40%;
    height: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--custom-secondary-bg);

    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      height: 20%;
    }
    & > div {
      padding: 1rem;

      img {
        max-width: 20rem;
        width: 24rem;
        height: 100%;
        object-fit: cover;
      }
    }
    .signUpForNonBss {
      text-align: center;
      margin-top: 2rem;
      @media (max-width: 768px) {
        margin-top: 0rem;
        padding: 0px;
      }
      p {
        font-size: 1.2rem;
        color: #fff;
        margin-bottom: 2rem;
      }
      button {
        background-color: transparent;
        padding: 0.4rem 2rem;
        font-size: 1rem;
        text-transform: uppercase;
        border: 1px solid #fff;
        border-radius: 2.5rem;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  .AuthenicationContent {
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;

    @media (max-width: 768px) {
      width: 100%;
      height: 80%;
    }

    .registerContent {
      background: white;
      padding: 10px;
      z-index: 10;
      height: 90%;
      border-radius: 1rem;
      margin: auto;
      overflow: auto;
      width: 90%;
      display: grid;
      place-items: center;
    }
  }
  p {
    font-size: clamp(12px, 18px, 17px);
    text-align: center;

    strong {
      font-size: clamp(14px, 18px, 20px);
    }
  }
  .AuthRadioMode {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    input {
      margin-left: 1rem;
    }
  }
  .inputOuter {
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .recaptcha {
    display: flex;
    margin: 1rem 0 3rem;
    justify-content: center;
  }
  .googleLoginBtn {
    margin: 1rem 0 3rem;
    display: flex;

    justify-content: center;
  }
`

const FormWrapper = styled.div`
  height: ${(props) => (props.mode !== 'forgot_password' ? '60vh' : '400px')};
  background: white;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  border-radius: 10px;
  align-items: center;
  max-width: 550px;
  min-width: 450px;
  z-index: 10;
  margin: 12px;
  overflow: auto;
  @media screen and (max-width: 525px) {
    width: 90%;
    min-width: 300px;
  }

  ${(props) =>
    props.isBssActive === 'NonBss'
      ? `
    @media screen and (max-height: 950px) {
    height: ${props.mode !== 'forgot_password' ? '95vh' : '400px'};
    gap: 0rem;
  }
    `
      : `  @media screen and (max-height: 780px) {
    height: ${props.mode !== 'forgot_password' ? '85vh' : '400px'};
  }`}

  @media screen and (max-height: 550px) {
    gap: 0rem;
  }
`

export const AuthMainContainer = ({ isBssActive, mode, children }) => {
  const navigate = useNavigate()

  const handleSignUp = () => {
    navigate('/signup', { replace: true })
  }
  return (
    <StyledContainer>
      <AuthMainOuter>
        <div className='BrndLogin'>
          <CompanyLogo />
          {isBssActive === 'NonBss' && (
            <div className='signUpForNonBss'>
              <p>New here? Sign up now!</p>
              <button onClick={handleSignUp}>SIGN UP</button>
            </div>
          )}
        </div>
        <div className='overlay'></div>
        <div className='AuthenicationContent'>
          {mode === 'register' ? (
            <div className='registerContent'>
              <AuthenticationHeader mode={mode} />
              {children}
            </div>
          ) : (
            <FormWrapper mode={mode} isBssActive={isBssActive}>
              <AuthenticationHeader mode={mode} />
              {children}
            </FormWrapper>
          )}
        </div>
      </AuthMainOuter>
    </StyledContainer>
  )
}
