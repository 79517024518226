import reminder from "../../../static/images/navigation/new_navigation/reminder.png";
import student from "../../../static/images/navigation/new_navigation/student.png";
import Interview from "../../../static/images/navigation/new_navigation/interview.png";
import Scholorship from "../../../static/images/navigation/new_navigation/scholarship.png";
import Confirmation from "../../../static/images/navigation/new_navigation/confirmation.png";
import Finance from "../../../static/images/navigation/new_navigation/finance.png";
import home from "../../../static/images/navigation/new_navigation/dashboard.png";
import Analytics from "../../../static/images/navigation/Group 45.svg";
import { ACCOUNT_TYPES, STUDENT_ADMISSION_STATUS_TYPES } from "../../../config/store/constants";

export const navList = [
  {
    icon: home,
    label: "Dashboard",
    location: "/",
    key: "dashboard",
    availableFor: [ACCOUNT_TYPES.STUDENT],
    condition: (profile) => {
      return true;
    },
  },

  {
    icon: home,
    label: "Dashboard",
    location: "/",
    key: "dashboard",
    availableFor: [ACCOUNT_TYPES.PRO],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: home,
    label: "Dashboard",
    location: "/",
    key: "dashboard",
    availableFor: [ACCOUNT_TYPES.REGIONAL_HEAD],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: home,
    label: "Dashboard",
    location: "/",
    key: "dashboard",
    availableFor: [ACCOUNT_TYPES.SCHOOL_HEAD],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: home,
    label: "Dashboard",
    location: "/",
    key: "dashboard",
    availableFor: [ACCOUNT_TYPES.SUPERADMIN],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Analytics,
    label: "Batch",
    location: "/batchInfo",
    key: "batch",
    availableFor: [ACCOUNT_TYPES.STUDENT],
    condition: (profile) => {
      return ![STUDENT_ADMISSION_STATUS_TYPES.NOT_APPLIED, STUDENT_ADMISSION_STATUS_TYPES.CANCELLED].includes(
        profile?.account_data?.admission_status?.admission_status
      );
    },
  },
  {
    icon: Scholorship,
    label: "Personal Information",
    location: "/personalinformation",
    key: "personalInformation",
    availableFor: [ACCOUNT_TYPES.STUDENT],
    condition: (profile) => {
      return ![STUDENT_ADMISSION_STATUS_TYPES.NOT_APPLIED, STUDENT_ADMISSION_STATUS_TYPES.CANCELLED].includes(
        profile?.account_data?.admission_status?.admission_status
      );
    },
  },
  {
    icon: Analytics,
    label: "Academic Information",
    location: "/academicinformation",
    key: "academicInformation",
    availableFor: [ACCOUNT_TYPES.STUDENT],
    condition: (profile) => {
      return ![STUDENT_ADMISSION_STATUS_TYPES.NOT_APPLIED, STUDENT_ADMISSION_STATUS_TYPES.CANCELLED].includes(
        profile?.account_data?.admission_status?.admission_status
      );
    },
  },

  {
    icon: Analytics,
    label: "Preferences",
    location: "/preferences",
    key: "preferences",
    availableFor: [ACCOUNT_TYPES.STUDENT],
    condition: (profile) => {
      return ![STUDENT_ADMISSION_STATUS_TYPES.NOT_APPLIED, STUDENT_ADMISSION_STATUS_TYPES.CANCELLED].includes(
        profile?.account_data?.admission_status?.admission_status
      );
    },
  },

  // ============================================= PRO
  {
    icon: student,
    label: "Students",
    location: "/pro/students",
    key: "students",
    availableFor: [ACCOUNT_TYPES.PRO],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: student,
    label: "BSS / NON-BSS",
    location: "/pro/students/bss-non-bss",
    key: "students_bss_non_bss",
    availableFor: [ACCOUNT_TYPES.PRO],
    condition: (profile) => {
      return true;
    },
  },

  {
    icon: Interview,
    label: "Interview",
    location: "/pro/interview",
    key: "interview",
    availableFor: [ACCOUNT_TYPES.PRO],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Confirmation,
    label: "Subject Confirmation",
    location: "/pro/subjectconfirmation",
    key: "subjectconfirmation",
    availableFor: [ACCOUNT_TYPES.PRO],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Finance,
    label: "Finance",
    location: "/pro/finance",
    key: "finance",
    availableFor: [ACCOUNT_TYPES.PRO],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: reminder,
    label: "Reminder",
    location: "/pro/reminder",
    key: "reminder",
    availableFor: [ACCOUNT_TYPES.PRO],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Scholorship,
    label: "Scholarship",
    location: "/pro/scholarship",
    key: "scholarship",
    availableFor: [ACCOUNT_TYPES.PRO],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Analytics,
    label: "Analytics",
    location: "/pro/analytics",
    key: "analytics",
    availableFor: [ACCOUNT_TYPES.PRO],
    condition: (profile) => {
      return true;
    },
  },
  // ============================================= Regional head
  {
    icon: Analytics,
    label: "Students",
    location: "/regionalhead/students",
    key: "students",
    availableFor: [ACCOUNT_TYPES.REGIONAL_HEAD],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Analytics,
    label: "Faculity",
    location: "/regionalhead/faculty",
    key: "faculity",
    availableFor: [ACCOUNT_TYPES.REGIONAL_HEAD],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Analytics,
    label: "Scholarship",
    location: "/regionalhead/scholarship",
    key: "scholarship",
    availableFor: [ACCOUNT_TYPES.REGIONAL_HEAD],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Analytics,
    label: "Fee Management",
    location: "/regionalhead/feemanagement",
    key: "feemanagement",
    availableFor: [ACCOUNT_TYPES.REGIONAL_HEAD],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Analytics,
    label: "Analytics",
    location: "/regionalhead/analytics",
    key: "analytics",
    availableFor: [ACCOUNT_TYPES.REGIONAL_HEAD],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Analytics,
    label: "Logs",
    location: "/regionalhead/logs",
    key: "logs",
    availableFor: [ACCOUNT_TYPES.REGIONAL_HEAD],
    condition: (profile) => {
      return true;
    },
  },
  // ============================================= school head
  {
    icon: Analytics,
    label: "Students",
    location: "/schoolhead/students",
    key: "students",
    availableFor: [ACCOUNT_TYPES.SCHOOL_HEAD],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Analytics,
    label: "Faculty",
    location: "/schoolhead/faculty",
    key: "faculty",
    availableFor: [ACCOUNT_TYPES.SCHOOL_HEAD],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Analytics,
    label: "Scholarship",
    location: "/schoolhead/scholarship",
    key: "scholarship",
    availableFor: [ACCOUNT_TYPES.SCHOOL_HEAD],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Analytics,
    label: "Fee Management",
    location: "/schoolhead/feemanagement",
    key: "feemanagement",
    availableFor: [ACCOUNT_TYPES.SCHOOL_HEAD],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Analytics,
    label: "Analytics",
    location: "/schoolhead/analytics",
    key: "analytics",
    availableFor: [ACCOUNT_TYPES.SCHOOL_HEAD],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Analytics,
    label: "Logs",
    location: "/schoolhead/logs",
    key: "logs",
    availableFor: [ACCOUNT_TYPES.SCHOOL_HEAD],
    condition: (profile) => {
      return true;
    },
  },
  // ============================================= Super admin
  {
    icon: Analytics,
    label: "Students",
    location: "/superadmin/students",
    key: "students",
    availableFor: [ACCOUNT_TYPES.SUPERADMIN],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Analytics,
    label: "Faculty",
    location: "/superadmin/faculty",
    key: "faculty",
    availableFor: [ACCOUNT_TYPES.SUPERADMIN],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Analytics,
    label: "Scholarship",
    location: "/superadmin/scholarship",
    key: "scholarship",
    availableFor: [ACCOUNT_TYPES.SUPERADMIN],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Analytics,
    label: "Fee Management",
    location: "/superadmin/feemanagement",
    key: "feemanagement",
    availableFor: [ACCOUNT_TYPES.SUPERADMIN],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Analytics,
    label: "Analytics",
    location: "/superadmin/analytics",
    key: "analytics",
    availableFor: [ACCOUNT_TYPES.SUPERADMIN],
    condition: (profile) => {
      return true;
    },
  },
  {
    icon: Analytics,
    label: "Logs",
    location: "/superadmin/logs",
    key: "logs",
    availableFor: [ACCOUNT_TYPES.SUPERADMIN],
    condition: (profile) => {
      return true;
    },
  },
];
