import React from 'react'
import styled from 'styled-components'
import { NavigationBrandContainer } from '../navigation/brand/Container'
import { NavigationLinksContainer } from '../navigation/links/Container'
import { FooterContainer } from '../footer/Container'
import { NavigationContext } from '../MainWrapper'
import Bar from '../../../static/images/navigation/white-bars.png'
import { useContext } from 'react'

const StyledComponent = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  background: var(--custom-secondary-bg);
  max-width: 270px;
  width: 270px;
  height: 100vh;
  padding: 1rem 0 0;

  @media screen and (max-width: 800px) {
    display: none;
  }
`
const StyledComponentMobile = styled.div`
  svg {
    path {
      fill: white !important;
    }
  }

  @media screen and (min-width: 800px) {
    display: none;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
    text-align: center;
    height: 50px;
    position: relative;
    .LogoPart {
      background-color: var(--custom-secondary-bg);
      align-items: center;
      text-align: center;
      display: flex;
      justify-content: space-between;
      padding: 0px 1rem;
      height: 50px;

      > div {
        width: 10rem;
      }
      .brandLogo {
        > div {
          padding: 0.5rem 1rem 0 0;
        }
      }
      .menuBar {
        padding: 5px 5px;
        border-radius: 3px;
        cursor: pointer;
        transition: all 0.25s ease-out;
        width: 30px;
        height: 30px;
        :hover {
          color: var(--custom-orange);
          border-color: var(--custom-orange);
        }

        @media screen and (max-width: 875px) {
          display: inline-block;
        }
      }
    }
    .LinksPart {
      position: absolute;
      text-align: center;
      left: 0;
      right: 0;
      z-index: 3;
      margin: 0 auto;
      box-shadow: 0px 10px 10px -17px #000;
      background: var(--custom-secondry-bg);
      > div:first-of-type {
        margin-top: 0rem;
      }
      .footerWrapper {
        padding: 0;
      }
      .logout-button {
        font-size: var(--font-12);
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`

const SideNavBar = () => {
  const { setMobileSubNavEnabled, mobileSubNavEnabled } =
    useContext(NavigationContext)
  return (
    <>
      <StyledComponent>
        <NavigationBrandContainer />
        <NavigationLinksContainer />
        <FooterContainer />
      </StyledComponent>
      <StyledComponentMobile>
        <div className='LogoPart'>
          <NavigationBrandContainer />

          <span
            className='menuBar'
            onClick={() => {
              setMobileSubNavEnabled((old) => !old)
            }}
          >
            {!mobileSubNavEnabled ? (
              <img className='menuBar' src={Bar} alt='menu bar' />
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                id='Icons'
                viewBox='0 0 24 24'
              >
                <path
                  fill='#000000'
                  d='M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1m4.707 14.293a1 1 0 1 1-1.414 1.414L12 13.414l-3.293 3.293a1 1 0 1 1-1.414-1.414L10.586 12 7.293 8.707a1 1 0 1 1 1.414-1.414L12 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414L13.414 12Z'
                ></path>
              </svg>
            )}
          </span>
        </div>
        {mobileSubNavEnabled && (
          <div className='LinksPart'>
            <NavigationLinksContainer />
            <FooterContainer />
          </div>
        )}
      </StyledComponentMobile>
    </>
  )
}

export default SideNavBar
