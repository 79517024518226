import styled from "styled-components";
import { Link } from "react-router-dom";
import logoutImage from "../../../static/images/navigation/_icons.svg";

const StyledComponents = styled.div`
  font-family: var(--font-1);
  padding-left: 1rem;
  width: 100%;
  display: flex;
  margin-top: auto;
  justify-self: flex-end;
  background-color: var(--custom-secondary-bg);

  .footerLogout {
    padding: 1rem;
    a {
      letter-spacing: 0.3px;
      color: var(--custom-white);
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      text-transform: uppercase;

      img {
        width: 1.6rem;
        height: 1.7rem;
        object-fit: contain;
      }
    }
  }
`;

export const FooterContainer = () => {
  return (
    <StyledComponents>
      <div className="footerLogout">
        <Link to="/logout" className="logout-button">
          <img src={logoutImage} alt="logout" />
          &nbsp; Log Out
        </Link>
      </div>
    </StyledComponents>
  );
};
