import styled from "styled-components";

const Wrapper = styled.div`
  text-align: center;
  padding-bottom: 1rem;
  .sub-header {
    font-size: 1.7rem;
    font-weight: 800;
    font-family: var(--font-1);
    color: var(--custom-btn-bg);
    letter-spacing: 0px;
    text-transform: uppercase;
  }
`;

const modeHeaderMapping = {
  login: "Login to your account",
  register: "create a new account",
  email_otp: "Email And OTP Verification",
  forgot_password: "FORGOT PASSWORD",
  create_password: "CREATE PASSWORD",
  reset_password: "ENTER NEW PASSWORD",
  phone_verification: "Phone Number Verification",
  email_verification: "Email Verification",
  google_signup: "SIGN UP WITH GOOGLE",
  cnic_verification: "CNIC Verification",
};

export const AuthenticationHeader = ({ mode }) => {
  return (
    <Wrapper>
      <div className="sub-header">{modeHeaderMapping[mode]}</div>
    </Wrapper>
  );
};
