import styled from "styled-components";

const FormElement = styled.form`
  width: 100%;
  justify-content: center;
  max-width: calc(100% - 66px);
  margin-top: 1rem;
  label {
    font-size: var(--font-16);
    letter-spacing: 0.5px;
    display: block;
    text-align: center;
    color: var(--custom-Black);
    line-height: 2rem;
  }

  .infoWrapper {
    margin: 1rem 1rem 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    * {
      font-size: 0.9rem;
      font-weight: 500;
    }
  }
  input {
    &:focus label,
    &:active label {
      font-size: var(--font-12) !important;
    }
  }
`;

export const FormComponent = ({ children }) => <FormElement className="formElement">{children}</FormElement>;
