import { AuthMainContainer } from '../components/AuthMainContainer'
import { RegistrationForm } from './Form'
import styled from 'styled-components'

const Wrapper = styled.div`
  .LoginFormOuter {
    border-radius: 10px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 0rem 2rem 2rem;
    .recaptcha {
      width: 100%;
      margin: 0 auto;
      max-width: 200px;
    }
    .seperationOR {
      margin: 2rem 0;
      border-bottom: 1px solid var(--custom-deep-line_break-color);
      width: 100%;
      position: relative;
      :after {
        content: 'OR';
        position: absolute;
        top: -11px;
        left: 0;
        right: 0;
        text-align: center;
        background-color: #ebecef;
        width: 40px;
        height: 24px;
        margin-left: auto;
        margin-right: auto;
        color: #000;
        font-size: 1rem;
      }
    }
    .googleLoginBtn {
      margin: 0rem;
      padding: 0.3rem;
      Button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.1px;
        padding-right: 1rem;
        gap: 0.1rem;
        font-size: 1rem;
        color: #fff;
        border-radius: 2.5rem;
        background-color: var(--custom-btn-bg);
      }
      .imgOuter {
        ${'' /* background-color: #fff; */}
        height: 100%;
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          padding: 0.1rem;
          width: 30px;
          height: 30px;
        }
      }
    }
    .headingInfo {
      &:before {
        content: ' *';
        font-weight: 600;
        color: var(--custom-badge-danger);
      }
      font-weight: 500;
      text-align: center;
      padding-bottom: 0.5rem;
    }
    form {
      max-width: 100%;
      width: 100%;
      gap: 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @media (max-width: 40rem) {
        flex-direction: column;
      }
      .inputOuter {
        width: 48%;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
      input {
        background: var(--custom-input-bg);
        border-radius: 25px;
        outline: none;
        border: 1px solid var(--custom-input-border);
        width: 100%;
      }
      label {
        text-align: center;
      }
      .SignupLogin > div {
        flex-direction: column;
        align-items: center;
        a {
          border: 1px solid var(--custom-orange);
          background-color: var(--custom-orange);
          color: var(--custom-white);
          padding: 0.4rem 2rem;
          border-radius: 10px;
        }
      }
    }
  }
`

const RegisterContainer = () => {
  return (
    <Wrapper>
      <AuthMainContainer mode={'register'}>
        <div className='LoginFormOuter'>
          <RegistrationForm />
        </div>
      </AuthMainContainer>
    </Wrapper>
  )
}

export default RegisterContainer
