import styled from "styled-components";
import { NavigationLink } from "./NavigationLink";
import { navList } from "../config";
import { createContext, useContext, useState } from "react";
import { GlobalUserProfileContext } from "../../../../App";

const StyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  background: var(--custom-secondary-bg);
  font-family: var(--font-1);
  gap: 10px;
`;

export const NavigationLinksContainer = () => {
  const { profile } = useContext(GlobalUserProfileContext);
  return (
    <StyledComponent>
      {navList.map((item, i) => {
        if (item.availableFor) {
          if (profile?.account_type && item.availableFor.includes(profile.account_type)) {
            return <NavigationLink key={i} config={item} />;
          }
          return null;
        }
        return <NavigationLink key={i} config={item} />;
      })}
    </StyledComponent>
  );
};
